/*-----Chat----*/
.chat {
	outline: 0;
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: end;
	justify-content: flex-end;
	min-height: 100%;
}
.chat-line {
	padding: 0;
	text-align: left;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	+.chat-line {
		padding-top: 1rem;
	}
}
.chat-message {
	position: relative;
	display: inline-block;
	background-color: #467fcf;
	color: $white;
	font-size: 0.875rem;
	padding: .375rem .5rem;
	border-radius: 3px;
	white-space: normal;
	text-align: right;
	margin: 0 .5rem 0 2.5rem;
	line-height: 1.4;
	> :last-child {
		margin-bottom: 0 !important;
	}
	&:after {
		content: "";
		position: absolute;
		left: -5px;
		top: 7px;
		border-bottom: 6px solid transparent;
		border-right: 6px solid #467fcf;
		border-top: 6px solid transparent;
	}
	img {
		max-width: 100%;
	}
	p {
		margin-bottom: 1em;
	}
}
.chat-line-friend {
	-ms-flex-direction: row;
	flex-direction: row;
	+.chat-line-friend {
		margin-top: -.5rem;
		.chat-author {
			visibility: hidden;
		}
		.chat-message:after {
			display: none;
		}
	}
	.chat-message {
		background-color: #f3f3f3;
		color: #495057;
		margin-right: .5rem;
		margin-left: 2.5rem;
		&:after {
			left: auto;
			right: -5px;
			border-right-width: 0;
			border-left: 5px solid #f3f3f3;
		}
	}
}
#messages-main {
	position: relative;
	margin: 0 auto;
	border: $border;
	margin-bottom: 1.5rem;
	&:after, &:before {
		content: " ";
		display: table;
	}
	.ms-user {
		padding: 15px;
		background: $white;
		border: 1px solid #eceff9;
		border-right: 0;
		>div {
			overflow: hidden;
			padding: 3px 5px 0 15px;
			font-size: 11px;
		}
	}
	#ms-compose {
		position: fixed;
		bottom: 120px;
		z-index: 0;
		left: 30px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	}
}
@media (min-width: 992px) {
	#messages-main {
		.ms-menu {
			position: absolute;
			right: 0;
			top: 0;
			border-left: $border;
			padding-bottom: 0;
			height: 100%;
			width: 300px;
			/* background: $white; */
		}
		.ms-body {
			padding-right: 300px;
		}
	}
	.msb-reply {
		border-right: 0 !important;
		border-left: 0 !important;
	}
}
@media (max-width: 991px) {
	#messages-main {
		.ms-menu {
			z-index: 0;
			margin-bottom: 25px;
			&.toggled {
				display: block;
			}
		}
		.ms-body {
			overflow: hidden;
		}
	}
}
@media (max-width: 480px) {
	.ah-actions {
		float: none !important;
		text-align: center;
		display: inline-block;
	}
	.d-flex.chat-user {
		display: none !important;
	}
	.ah-actions {
		margin-right: 50px !important;
	}
	.actions>li>a {
		padding: 0 5px !important;
	}
}
#ms-menu-trigger {
	user-select: none;
	position: absolute;
	right: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding-left: 10px;
	padding-top: 24px;
	cursor: pointer;
	text-align: left;
	i {
		font-size: 21px;
	}
	&.toggled i:before {
		content: "";
	}
}
.login-content:after {
	content: "";
}
#footer, .fc-toolbar .ui-button, .fileinput .thumbnail {
	text-align: center;
}
.four-zero {
	text-align: center;
	footer>a {
		text-align: center;
	}
}
.ie-warning, .login-content, .login-navigation {
	text-align: center;
}
.pt-inner {
	text-align: center;
	.pti-footer>a {
		text-align: center;
	}
}
.message-feed {
	padding: 20px;
	&.left>.pull-left {
		margin-right: 15px;
	}
	&:not(.left) .mf-content {
		background: #eaeefa;
		position: relative;
		margin-right: 5px;
	}
	&.left .mf-content {
		background: $primary;
		color: $white;
		position: relative;
		margin-left: 5px;
	}
}
.mf-content {
	padding: 13px 20px;
	border-radius: 6px;
	display: inline-block;
	max-width: 100%;
}
.mf-date {
	display: block;
	color: $muted;
	margin-top: 7px;
	>i {
		font-size: 14px;
		line-height: 100%;
		position: relative;
		top: 1px;
	}
}
.four-zero, .lc-block {
	box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
}
.msb-reply {
	position: relative;
	background: $white;
	border: 1px solid #eceff9;
	height: 50px;
	border-bottom: 0;
	textarea {
		width: 100%;
		font-size: 13px;
		border: 0;
		padding: 15px 15px;
		resize: none;
		height: 50px;
		background: 0 0;
	}
	button {
		position: absolute;
		top: 9px;
		left: 9px;
		border: 0;
		height: 40px;
		width: 40px;
		font-size: 20px;
		color: $muted;
		background: 0 0;
	}
}
.ico-mt {
	margin-top: -0.5rem !important;
}
.msb-reply button:hover {
	background: transparent;
}
.p-15 {
	padding: 15px !important;
}
.actions {
	list-style: none;
	padding: 0;
	margin: 0;
	>li {
		display: inline-block;
	}
	&:not(.a-alt)>li>a>i {
		color: $white;
	}
	>li>a {
		>i {
			font-size: 20px;
		}
		display: block;
		padding: 0 10px;
	}
}
.message-feed.left {
	text-align: left;
}
#ms-menu-trigger, .toggle-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
}
.list-group.lg-alt .list-group-item {
	border: 0;
}
.action-header {
	position: relative;
	background: $primary;
	padding: 15px 13px 15px 17px;
	border-bottom: $border;
}
.ms-body {
	background: $white;
	border-radius: 7px;
}
.amcharts-chart-div a, .canvasjs-chart-container a {
	display: none !important;
}
.rotation {
	-webkit-animation: rotation 2s infinite linear;
}
@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}
.amChartsLegend.amcharts-legend-div {
	display: none;
}
.input-icon .form-group.is-empty {
	.form-control {
		margin-bottom: 0;
	}
	margin-bottom: 0;
}
/*=====================================
  Inbox Message Style
  =======================================*/

.inbox-message ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
		position: relative;
		padding: 15px 20px;
		border-bottom: $border;
		&:hover, &:focus {
			background: #ebeff8;
			z-index: 0;
		}
	}
}
.chat-conatct-list .media:hover {
	z-index: inherit;
}
.inbox-message .message-avatar {
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
}
.message-avatar img {
	display: inline-block;
	width: 54px;
	height: 54px;
	border-radius: 50%;
}
.inbox-message .message-body {
	margin-right: 85px;
	font-size: 15px;
	color: $color;
}
.message-body-heading h5 {
	font-weight: 600;
	display: inline-block;
	color: $color;
	margin: 0 0 7px 0;
	padding: 0;
}
.message-body h5 span {
	border-radius: 50px;
	line-height: 14px;
	font-size: 12px;
	color: $white;
	font-style: normal;
	padding: 4px 10px;
	margin-right: 5px;
	margin-top: -5px;
	&.unread {
		background: #07b107;
	}
	&.important {
		background: #dd2027;
	}
	&.pending {
		background: #2196f3;
	}
}
.message-body-heading span {
	float: left;
	font-size: 14px;
}
.messages-inbox .message-body p {
	margin: 0;
	padding: 0;
	line-height: 27px;
	font-size: 15px;
}
@media (max-width: 600px) {
	.job-box-filter label {
		width: 100%;
		text-align: center;
	}
	.message-body h5 span {
		&.pending, &.unread {
			display: none;
		}
	}
}
.list-icons i {
	font-size: 56px;
}
.font-dot {
	margin-top: -12px;
}
.list-icons .card-footer i {
	font-size: 14px;
}
.chat-body-style {
	background:$white;
}
.chat-conatct-list {
	max-height: 555px;
	overflow-y: auto;
}
.msg_card_body {
	background: url(../images/chat.png);
	overflow-y: auto;
}
.chat-conatct-list .media {
	overflow: initial;
}
.message-feed {
	&.media .media-body, &.left .media-body {
		overflow: visible;
	}
	&:not(.left) .mf-content:before {
		content: '';
		position: absolute;
		top: 9px;
		border-bottom: 8px solid #eaeefa;
		border-right: 8px solid transparent;
		border-left: 8px solid transparent;
		left: auto;
		right: -12px;
		transform: rotate(90deg);
		display: block;
	}
	&.left .mf-content:before {
		content: '';
		position: absolute;
		top: 9px;
		border-bottom: 8px solid $primary;
		border-right: 8px solid transparent;
		border-left: 8px solid transparent;
		right: auto;
		left: -12px;
		transform: rotate(270deg);
		display: block;
	}
	&.left .media-body{
		display: flow-root;
	}
}
.ms-menu .tab-menu-heading {
	background: $white;
	padding: 13px;
	border-radius: 5px 0 0 0;
	border-left: 0;
	position: sticky;
	border-right: 0;
	width: 299px;
	z-index: 1;
	.tabs-menu1 ul li {
		.active {
			border: 0;
			background: $primary;
			color: $white;
			border: 1px solid $primary;
		}
		width: 48%;
		margin: 0 1px;
		a {
			border-radius: 5px;
			padding: 10px 20px;
			text-align: center;
			font-weight: 600;
			border: 1px solid #d3dfea;
		}
	}
}
.chat-conatct-list .list-group-item {
	position: relative;
	cursor: pointer;
	.chat-time {
		position: absolute;
		top: 15px;
		left: 10px;
		font-size: 12px;
	}
}
.contacts_body {
	white-space: nowrap;
	.contacts {
		list-style: none;
		padding: 0;
		li {
			width: 100% !important;
			padding: 15px;
			border-bottom: 1px solid #ebeaf1;
		}
	}
	.active {
		background-color: #eaeefa;
	}
}
.chatbox {
	.user_info {
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 15px;
		span {
			color: #464444;
		}
	}
	.action-header small {
		font-size: 10px;
	}
	.user_img {
		height: 50px;
		width: 50px;
		border: 0px solid #f5f6fa;
	}
	.user_img_msg {
		height: 40px;
		width: 40px;
		border: 1.5px solid #f5f6fa;
	}
	.img_cont {
		position: relative;
		height: 50px;
		width: 50px;
	}
	.img_cont_msg {
		height: 40px;
		width: 40px;
		display: contents;
	}
}
.chat-box-single-line {
	margin: 7px 0 30px;
	position: relative;
	text-align: center;
}
.chat abbr.timestamp {
	padding: 4px 14px;
	border-radius: 4px;
}
.chat {
	.action-header {
		position: relative;
		padding: 15px 13px 15px 17px;
	}
	.dropdown-menu.dropdown-menu-left.show {
		width: 100%;
		margin-bottom: 5px;
		position: absolute;
		transform: translate3d(-176px, 22px, 0px);
		top: 10px !important;
		right: 0px;
		will-change: transform;
	}
	.msb-reply textarea {
		width: 100%;
		font-size: 13px;
		padding: 12px 47px;
		resize: none;
		height: 44px;
		border: 1px solid #ebeaf1;
		background: #FAF9FF;
		margin-right: -42px;
	}
	.dropdown-menu li {
		padding: 7px;
		color: #5e629c;
		&:hover {
			background: #FAF9FF;
		}
		i {
			padding: 7px;
		}
	}
	.actions {
		>li {
			display: inline-block;
		}
		&:not(.a-alt)>li>a>i {
			color: $white;
			line-height: unset;
		}
	}
	.justify-content-start, .justify-content-end {
		margin-bottom: 25px;
	}
	margin-bottom: auto;
	.card {
		height: 600px;
	}
	.card-header {
		padding: 1rem;
	}
	.video_cam {
		margin-right: 50px;
		margin-top: 5px;
		span {
			color: white;
			font-size: 20px;
			cursor: pointer;
			margin-left: 20px;
		}
	}
	.msg_cotainer {
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 10px;
		background-color: #eaeefa;
		padding: 10px;
		position: relative;
		border-radius: 5px;
		max-width: 70%;
		margin-top: 5px;
		&:before {
			content: '';
			position: absolute;
			top: 9px;
			border-bottom: 8px solid #eaeefa;
			border-right: 8px solid transparent;
			border-left: 8px solid transparent;
			left: auto;
			right: -12px;
			transform: rotate(90deg);
			display: block;
		}
	}
	.msg_cotainer_send {
		&:after {
			content: '';
			position: absolute;
			top: 9px;
			border-bottom: 8px solid $primary;
			border-right: 8px solid transparent;
			border-left: 8px solid transparent;
			right: auto;
			left: -12px;
			transform: rotate(270deg);
			display: block;
		}
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 10px;
		background-color: $primary;
		padding: 10px;
		position: relative;
		color: $white;
		border-radius: 5px;
		max-width: 70%;
		margin: 0 0px 10px 12px;
	}
	.msg_time {
		position: absolute;
		right: 0;
		bottom: -18px;
		color: #3a374e;
		font-size: 10px;
	}
	.msg_time_send {
		position: absolute;
		left: 0;
		bottom: -18px;
		color: #3a374e;
		font-size: 10px;
	}
	.msg_head {
		position: relative;
		background: #60529f;
		box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.4);
	}
}
#action_menu_btn {
	position: absolute;
	left: 36px;
	top: 21px;
	color: white;
	cursor: pointer;
	font-size: 20px;
}
.action_menu {
	z-index: 1;
	position: absolute;
	padding: 15px 0;
	background-color: $white;
	color: #646367;
	top: 40px;
	left: 15px;
	display: none;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
}
.action_menu ul li:hover {
	cursor: pointer;
	background-color: $black-2;
}
.construction .form-control {
	padding: 0.375rem 1rem;
}
.modal .ah-actions {
	margin-top: 0px !important;
}
.modal-content .actions>li>a {
	display: block;
	/* padding: 10px; */
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin: 0 auto;
	line-height: 50px;
	text-align: center;
	&:hover {
		background: $black-1;
	}
}
.msb-reply-button .btn {
	line-height: 14px;
}
.modal-content.chat .card {
	border-radius: 4px 4px 0 0;
	box-shadow: none;
}
.ah-actions {
	z-index: 1;
	float: left !important;
	margin-top: 0px !important;
	position: relative;
	top:7px;
	&.actions a {
		line-height: inherit;
		height: auto;
	}
	.option-dots {
		width: 40px;
		height: 40px !important;
		border-radius: 5px;
		background: transparent;
		margin: 0 1px;
		text-align: center;
		line-height: 40px !important;
		display: block !important;
		padding: 0 !important;
		font-size: 18px;
		color:#565b95;
	}
}
.ms-menu .dropdown-menu li a{
	font-size: 13px;
}

.conv-form-wrapper div#messages div.message.to {
	background: rgb(238, 239, 246);
}
div.conv-form-wrapper div.options div.option {
	border: 1px solid $primary;
	background: $white;
	color: $primary;
}
form.convFormDynamic button.submit {
	color: $primary;
	border: 1px solid $primary;
	background: $white;
}
div.conv-form-wrapper div.options div.option.selected {
	background: $primary;
	color: $white;
	&:hover {
		background: $primary;
	}
}
form.convFormDynamic button.submit:hover {
	background: $primary;
	color: $white;
}
div.conv-form-wrapper div#messages div.message.from {
	background: $primary;
	color: $white;
	border-top-left-radius: 0;
}