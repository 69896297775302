/*------ Carousel -------*/

.carousel {
	position: relative;
}
.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}
.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		border-radius: 50%;
		background-color: $white;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $primary;
	}
}
.carousel-indicators1 {
	position: absolute;
	right: 0;
	top: 10px;
	left: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}
.carousel-indicators2 {
	position: absolute;
	right: 10px;
	top: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-top: 10px;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}
.carousel-indicators3 {
	position: absolute;
	left: 10px;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-top: 10px;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}
.carousel-indicators4 {
	position: absolute;
	right: 10px;
	bottom: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-top: 10%;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}
.carousel-indicators5 {
	position: absolute;
	bottom: 0;
	left: 10px;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}
.carousel-caption {
	position: absolute;
	right: 15%;
	top: 30%;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: $white;
	text-align: center;
}
.carousel-item-background {
	content: '';
	background: $black-5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.carousel-top-indicators {
	top: 15px;
}
.carousel-top-right-indicators {
	top: 15px;
	right: 15px !important;
	left: auto;
	margin: 0 !important;
}
.carousel-top-left-indicators {
	top: 15px;
	left: 15px !important;
	right: auto;
	margin: 0 !important;
}
.carousel-bottom-right-indicators {
	bottom: 15px;
	right: 15px !important;
	left: auto;
	margin: 0 !important;
}
.carousel-bottom-left-indicators {
	bottom: 15px;
	left: 15px !important;
	right: auto;
	margin: 0 !important;
}

.dashboard-carousel .carousel-control-prev, .dashboard-carousel .carousel-control-next {
    background: $white;
    border: 1px solid#e9ebfa;
    width: 1.625rem;
    height: 1.625rem;
    top: 1.062rem;
    border-radius: 0.375rem;
    opacity: 1;
}
.dashboard-carousel .carousel-control-next {
    left: 1.8rem;
    right: auto;
}
.dashboard-carousel .carousel-control-prev {
    left: 4rem;
    right: auto;
}
.dashboard-carousel .carousel-control-prev .carousel-control-prev-icon, .dashboard-carousel .carousel-control-next .carousel-control-next-icon {
    background-image: none;
}
.dashboard-carousel .carousel-control-prev-icon, .dashboard-carousel .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
}
.dashboard-carousel .carousel-control-prev .carousel-control-prev-icon::after {
    content: '\e92f';
    font-family: 'feather' !important;
    display: block;
    font-size: 0.812rem;
    color: #313e6a;
    font-weight: bold;
    line-height: 1.35rem;
    margin-right: .1rem;
}
.dashboard-carousel .carousel-control-next .carousel-control-next-icon::after {
    content: '\e92e';
    font-family: 'feather' !important;
    color:#313e6a;
    font-weight: bold;
    font-size: 0.812rem;
    display: block;
    line-height: 1.35rem;
}
.dashboard-carousel .carousel-body .text-over {
    white-space: nowrap;
	overflow: hidden;
	width:55%;
    text-overflow: ellipsis;
}
.carousel-inner>.item> {
	img, a>img {
		width: 100%;
	}
}
.carousel-slider img{
	width: 60px;
    height: 60px;
    border: $border;
    border-radius: 5px;
    margin: 3px;
    display: inline-block;
    overflow: hidden;
}
.product-carousel .carousel-item img{
	margin: 0 auto;
    width: 60%;
    text-align: center;
    display: block;
	background: #f1f4fb;
	padding: 2rem;
	border-radius: 5px;
}
.carousel-slider .thumb{
    margin: 0 2px;
    cursor: pointer;
    float: left;
	&.active img{
		background: #f1f4fb;
	}
}
#thumbcarousel{
	margin: 0 auto 20px auto;
    display: table;
}