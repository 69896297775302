/*---- Tags-----*/

.tag {
	font-size: 0.75rem;
	color: $color;
	background-color: #ebeff8;
	border-radius: 3px;
	padding: 0 .5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
a.tag {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background-color: rgba(110, 118, 135, 0.2);
		color: inherit;
	}
}
.tag-addon {
	display: inline-block;
	padding: 0 .5rem;
	color: inherit;
	text-decoration: none;
	background: #e8ebf3;
	margin: 0 .5rem 0 -.5rem;
	text-align: center;
	min-width: 1.5rem;
	&:last-child {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	i {
		vertical-align: middle;
		margin: 0 -.25rem;
	}
}
a.tag-addon {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background: rgba(0, 0, 0, 0.16);
		color: inherit;
	}
}
.tag-avatar {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 0px 3px 3px 0px;
	margin: 0 -.5rem 0 .5rem;
}
.tag-blue {
	background-color: $blue;
	color: $white;
}
.tag-indigo {
	background-color: $indigo;
	color: $white;
}
.tag-purple {
	background-color: $purple;
	color: $white;
}
.tag-pink {
	background-color: $pink;
	color: $white;
}
.tag-red {
	background-color: #dc0441;
	color: $white;
}
.tag-orange {
	background-color: $orange;
	color: $white;
}
.tag-yellow {
	background-color: $warning;
	color: $white;
}
.tag-green {
	background-color: $success;
	color: $white;
}
.tag-teal {
	background-color: $teal;
	color: $white;
}
.tag-cyan {
	background-color: #17a2b8;
	color: $white;
}
.tag-white {
	background-color: $white;
	color: $white;
}
.tag-gray {
	background-color: #868e96;
	color: $white;
}
.tag-gray-dark {
	background-color: $dark;
	color: $white;
}
.tag-azure {
	background-color: $info;
	color: $white;
}
.tag-lime {
	background-color: #7bd235;
	color: $white;
}
.tag-primary {
	background-color: #467fcf;
	color: $white;
}
.tag-secondary {
	background-color: $secondary;
	color: $white;
}
.tag-success {
	background-color: $success;
	color: $white;
}
.tag-info {
	background-color: $info;
	color: $white;
}
.tag-warning {
	background-color: $warning;
	color: $white;
}
.tag-danger {
	background-color: #dc0441;
	color: $white;
}
.tag-light {
	background-color: #f8f9fa;
	color: $white;
}
.tag-dark {
	background-color: $dark;
	color: $white;
}
.tag-rounded {
	border-radius: 50px;
	.tag-avatar {
		border-radius: 50px;
	}
}
.tags {
	margin-bottom: -.5rem;
	font-size: 0;
	>.tag {
		margin-bottom: .5rem;
		&:not(:last-child) {
			margin-left: .5rem;
		}
	}
}
